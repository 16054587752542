import {Component, OnChanges, SimpleChange, SimpleChanges} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {IconService} from "@visurel/iconify-angular";
import {appIcons} from "./app.icons";
import {DateAdapter} from "@angular/material/core";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor( iconService: IconService,
    private translate: TranslateService,
  ) {
    iconService.registerAll(appIcons);
    this.initializeApp()
  }

  initializeApp() {
    this.translate.setDefaultLang('en');
  }
}
