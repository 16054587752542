import {Component, ElementRef, ViewChild, AfterViewInit, Output, Input, EventEmitter, NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { ModalController,IonicModule, } from '@ionic/angular';
import Cropper from 'cropperjs';
import {AppComponent} from "../app.component";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})

export class ImageCropperComponent {
  @Input() imageUrl: string;  // 从父组件接收图片 URL

  @ViewChild('imageElement', { static: false }) imageElement!: ElementRef;
  @Output() croppedImage = new EventEmitter<string>(); // Output the cropped image data

  cropper!: Cropper;

  zoomLevel: number = 1;

  constructor(private modalController: ModalController) {
  }


  ngAfterViewInit() {
    this.initializeCropper();
  }

  initializeCropper() {
    const image = this.imageElement.nativeElement;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const minContainerWidth = Math.min(screenWidth, 400); // 最大宽度不超过400px
    const minContainerHeight = Math.min(screenHeight, 800); // 高度同样限制
    this.cropper = new Cropper(image, {
      aspectRatio: 1, // 剪切框比例为1
      viewMode: 1, // 限制剪切框在图片边界内
      autoCropArea: 1,
      responsive: true,
      scalable: true,
      zoomable: true,
      movable: true,
      dragMode: 'move', // 允许图片移动而不是裁剪框
      cropBoxResizable: true, // 禁止调整裁剪框大小,
      minContainerWidth:minContainerWidth,
      minContainerHeight:minContainerHeight,
      ready: () => {
        // 设置剪裁框的宽度为屏幕宽度的100%，但最大宽度不超过400px
        const cropBoxData = this.cropper.getCropBoxData();
        const targetWidth = Math.min(screenWidth, 400);
        this.cropper.setCropBoxData({
          width: targetWidth,
          height: targetWidth,
          left: (screenWidth - targetWidth) / 2, // 水平居中
        });
      },
    });
  }

  resetCropper() {
    this.cropper.reset();
  }

  getCroppedImage() {
    const canvas = this.cropper.getCroppedCanvas();
    this.croppedImage.emit(canvas.toDataURL('image/png'));
  }

  rotateImage() {
    this.cropper.rotate(90);
  }

  async confirm() {
    const croppedCanvas = this.cropper.getCroppedCanvas();
    const croppedImage = croppedCanvas.toDataURL('image/png');
    // 返回裁剪后的图片数据
    const data = croppedImage;
    await this.modalController.dismiss(data);
  }


  async close() {
    await this.modalController.dismiss();
  }
}
