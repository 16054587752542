import { Component, OnInit } from '@angular/core';
import {SwiperOptions} from "swiper";
import {ActivatedRoute, Router} from "@angular/router";
import {IonRouterOutlet, NavController} from "@ionic/angular";

@Component({
  selector: 'app-imeasure-tutorial',
  templateUrl: './imeasure-tutorial.component.html',
  styleUrls: ['./imeasure-tutorial.component.scss'],
})
export class ImeasureTutorialComponent implements OnInit {

  loading: boolean= true;
  slides = [
    {
      image: '/assets/images/tutorial-slide-1.jpg'
    },
    {
      image: '/assets/images/tutorial-slide-2.jpg'
    },
    {
      image: '/assets/images/tutorial-slide-3.jpg'
    },
    {
      image: '/assets/images/tutorial-slide-4.jpg'
    },
    {
      image: '/assets/images/tutorial-slide-5.jpg'
    },
    {
      image: '/assets/images/tutorial-slide-6.jpg'
    },

  ];
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: false,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    autoplay: {
      delay: 4000,
    },
  };
  title: string = "Loading";

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private navCtrl: NavController,
    private routerOutlet: IonRouterOutlet
  ) {}

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.routerOutlet.swipeGesture = false;
  }

  ionViewWillLeave() {
    this.routerOutlet.swipeGesture = true;
  }

  back() {
    this.navCtrl.back();
  }

}
