import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tab1',
    loadChildren: () => import('./tab1/tab1.module').then(m => m.Tab1PageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'general',
    loadChildren: () => import('./general/general.module').then(m => m.GeneralPageModule)
  },
  {
    path: 'body',
    loadChildren: () => import('./body/body.module').then(m => m.BodyPageModule)
  },
  {
    path: 'uniform',
    loadChildren: () => import('./uniform/uniform.module').then(m => m.UniformPageModule)
  },
  // {
  //   path: 'spring-female',
  //   loadChildren: () => import('./spring-female/spring-female.module').then( m => m.SpringFemalePageModule)
  // },
  {
    path: 'profilecreate1',
    loadChildren: () => import('./profilecreate1/profilecreate1.module').then(m => m.Profilecreate1PageModule)
  },
  {
    path: 'profilecreate2',
    loadChildren: () => import('./profilecreate2/profilecreate2.module').then(m => m.Profilecreate2PageModule)
  },
  {
    path: 'profilecreate4',
    loadChildren: () => import('./profilecreate4/profilecreate4.module').then(m => m.Profilecreate4PageModule)
  },
  {
    path: 'profilecreate5',
    loadChildren: () => import('./profilecreate5/profilecreate5.module').then(m => m.Profilecreate5PageModule)
  },
  {
    path: 'profilecreate6',
    loadChildren: () => import('./profilecreate6/profilecreate6.module').then(m => m.Profilecreate6PageModule)
  },
  {
    path: 'profilecreate8',
    loadChildren: () => import('./profilecreate8/profilecreate8.module').then(m => m.Profilecreate8PageModule)
  },
  {
    path: 'recommendation1',
    loadChildren: () => import('./recommendation1/recommendation1.module').then(m => m.Recommendation1PageModule)
  },
  // {
  //   path: 'profileerror',
  //   loadChildren: () => import('./profileerror/profileerror.module').then( m => m.ProfileerrorPageModule)
  // },
  // {
  //   path: 'profile4',
  //   loadChildren: () => import('./profile4/profile4.module').then( m => m.Profile4PageModule)
  // },
  // {
  //   path: 'home1',
  //   loadChildren: () => import('./home1/home1.module').then( m => m.Home1PageModule)
  // },
  {
    path: 'profile2',
    loadChildren: () => import('./profile2/profile2.module').then( m => m.Profile2PageModule)
  },
  // {
  //   path: 'profile3',
  //   loadChildren: () => import('./profile3/profile3.module').then( m => m.Profile3PageModule)
  // },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordPageModule)
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./resetpassword/resetpassword.module').then(m => m.ResetpasswordPageModule)
  },
  {
    path: 'editprofile',
    loadChildren: () => import('./editprofile/editprofile.module').then(m => m.EditprofilePageModule)
  },
  {
    path: 'measure-results',
    loadChildren: () =>
      import('./measure-results/measure-results.module').then(
        (m) => m.MeasureResultsPageModule
      ),
    // canActivate: [HomeGuard],
    // canActivateChild: [HomeGuard],

  },
  {
    path: 'measure-camera',
    loadChildren: () =>
      import('./measure-camera/measure-camera.module').then(
        (m) => m.MeasureCameraPageModule
      ),
    // canActivate: [HomeGuard],
    // canActivateChild: [HomeGuard],

  },
  {
    path: 'measure-edit',
    loadChildren: () =>
      import('./measure-edit/measure-edit.module').then(
        (m) => m.MeasureEditPageModule
      ),
    // canActivate: [HomeGuard],
    // canActivateChild: [HomeGuard],

  },
  {
    path: 'result',
    loadChildren: () => import('./result/result.module').then(m => m.ResultPageModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./booking/booking.module').then(m => m.BookingPageModule)
  },
  {
    path: 'profile1',
    loadChildren: () => import('./profile1/profile1.module').then(m => m.Profile1PageModule)
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./changepassword/changepassword.module').then(m => m.ChangepasswordPageModule)
  },
  {
    path: 'termsofservice',
    loadChildren: () => import('./termsofservice/termsofservice.module').then(m => m.TermsofservicePageModule)
  },
  {
    path: 'privatepolicy',
    loadChildren: () => import('./privatepolicy/privatepolicy.module').then(m => m.PrivatepolicyPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./contactus/contactus.module').then(m => m.ContactusPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./product-detail/product-detail.module').then(m => m.ProductDetailPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'order-history',
    loadChildren: () => import('./order-history/order-history.module').then( m => m.OrderHistoryPageModule)
  },
  {
    path: 'bind-email',
    loadChildren: () => import('./bind-email/bind-email.module').then( m => m.BindEmailPageModule)
  },
  {
    path: 'measure-history',
    loadChildren: () => import('./measure-history/measure-history.module').then( m => m.MeasureHistoryPageModule)
  },
  {
    path: 'camera-body',
    loadChildren: () => import('./camera-body/camera-body.module').then( m => m.CameraBodyPageModule)
  },




  // {
  //   path: 'profile4',
  //   loadChildren: () => import('./profile4/profile4.module').then( m => m.Profile4PageModule)
  // },
  // {
  //   path: 'changepassword',
  //   loadChildren: () => import('./changepassword/changepassword.module').then( m => m.ChangepasswordPageModule)
  // },
  // {
  //   path: 'termsofservice',
  //   loadChildren: () => import('./termsofservice/termsofservice.module').then( m => m.TermsofservicePageModule)
  // },
  // {
  //   path: 'privatepolicy',
  //   loadChildren: () => import('./privatepolicy/privatepolicy.module').then( m => m.PrivatepolicyPageModule)
  // },
  // {
  //   path: 'contactus',
  //   loadChildren: () => import('./contactus/contactus.module').then( m => m.ContactusPageModule)
  // },
  // {
  //   path: 'profile1',
  //   loadChildren: () => import('./profile1/profile1.module').then( m => m.Profile1PageModule)
  // }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
