import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse, HttpErrorResponse,
} from '@angular/common/http';
import {Observable, Observer, fromEvent, merge, of,throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {LoadingController, ModalController, NavController} from "@ionic/angular";
import {ImeasureTutorialComponent} from "./measure-edit/imeasure-tutorial/imeasure-tutorial.component";
import {AnyComponentStyleBudgetChecker} from "@angular-devkit/build-angular/src/webpack/plugins";
import { finalize } from 'rxjs/operators';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  private loadingModal: HTMLIonModalElement;
  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    public loadingCtrl: LoadingController
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log(request.url.toString());
    // this.openLoading();
    // @ts-ignore
    return next.handle(request).pipe(
      tap(
        (event) => {
          // alert('connection error 111');
          event instanceof HttpResponse ? 'succeeded' : '';
        },
        (err) => {
          console.log(err)
          this.closeLoading().then(()=>{
            alert('connection error');
          })
        }
      )
    );
}
//   catchError((err) => {
//   console.log('error caught in service')
//   console.error(err);
//   return throwError(err);
// })
//   this.closeLoading().then(()=>{
//   alert('connection error');
// })

  async closeLoading() {
    // this.router.navigate(['/ble']);
    return this.modalCtrl.dismiss("", "", environment.Tutorial_Loading_id);
  }
}
