import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DeviceMotion } from '@ionic-native/device-motion/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
// import {AuthInterceptor} from "./emtailor/auth-interceptor";
import { HttpErrorInterceptor } from './error-handling-interceptor';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import {IonicModule, IonicRouteStrategy, IonRouterOutlet} from '@ionic/angular';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import {NativeDateAdapter} from "@angular/material/core";
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {ImeasureTutorialComponent} from "./measure-edit/imeasure-tutorial/imeasure-tutorial.component";
import {MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {SwiperModule} from "swiper/angular";
import { ModuleWithProviders,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import {DateAdapter} from "@angular/material/core";
import { AuthInterceptor } from './emtailor/auth-interceptor';
import {ImageCropperComponent} from "./image-cropper/image-cropper.component";
import {LoadingDialogComponent} from "./shared/loading-dialog/loading-dialog.component";
// @ts-ignore
// import { SwiperModule } from 'swiper/angular';
// import { MultiPickerModule } from 'ion-multi-picker';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent,ImeasureTutorialComponent, ImageCropperComponent],
  entryComponents: [],
  // imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
  imports: [BrowserModule,
    IonicModule.forRoot() as ModuleWithProviders<{}>,
    AppRoutingModule,
    HttpClientModule,
    NoopAnimationsModule,
    CommonModule,
    IonicModule,

    // SwiperModule,
    // MultiPickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    // }),
    // SwiperModule],

    }) as ModuleWithProviders<{}>,
    SwiperModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DeviceMotion,
    Keyboard,
    CameraPreview,
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: IonRouterOutlet,
      useValue: {
        //add whatever property of IonRouterOutlet you're using in component class
        nativeEl: 'swipeGesture'
      }
    },{
      provide:MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {hasBackdrop: false}
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

