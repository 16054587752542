import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

/* Enums */
export enum Side { FRONT, SIDE, BACK };


/* Utility Functions */
export const normalizeIndex = (index: number, arr: any[]): number => {
  if (index < 0) return arr.length + index;
  if (index >= arr.length) return index - arr.length;
  return index;
};


/* API Types Definitions */

// Token
export type TokenResponse = {
  code: string,
  codeDesc: string,
  msg: string,
  data: TokenResponseData,
};

export type TokenResponseData = {
  token?: string,
  expirationMin?: string,
};


// Process
export type ProcessResponse = {
  code: string,
  codeDesc: string,
  msg: string,
  data: {
    ticket?: string,
    timeCostMillis?: number,
    errorInfo?: Array<{ id: number, position: string; }>,
    warnInfo?: Array<{ id: number, position: string; }>,
  },
};

export type ProcessResponseData = {
  ticket?: string,
  timeCostMillis?: number,
  errorInfo?: Array<{ id: number, position: string; }>,
  warnInfo?: Array<{ id: number, position: string; }>,
};


// Measure Data
export type MeasureDataResponse = {
  code: string,
  codeDesc: string,
  msg: string,
  data: MeasureDataResponseData,
};

export type MeasureDataResponseData = {
  measureId?: string,
  sizes?: Array<{
    sizeCode: string,
    sizeCmVal: number,
  }>,
  frontProfileBody?: MeasureDataResponseProfileBody,
  sideProfileBody?: MeasureDataResponseProfileBody,
  frontImgSrc: string,
  sideImgSrc: string,
  sr?: Array<{
    chartName: string,
    sizeRecmd: string,
  }>,
};

export type MeasureDataResponseProfileBody = {
  callPx?: Array<Point>,
  caddInfo?: string,
  clooseIdx?: Array<{
    loosePartStart: number,
    loosePartEnd: number,
  }>,
  cpaintLines?: Array<{
    lineName: string,
    imgDir: 'f' | 's' | 'b',
    lineType: 'L' | 'R' | 'free',
    startPt: Point,
    endPt: Point,
    location?: number,
    dnRange?: number,
    upRange?: number,
  }>,
  csizeLines?: Array<{
    lineSizeID: string,
    lineName: string,
    lineSizeVal: number,
    startPt: Point,
    endPt: Point,
  }>,
  cmovPxIdx?: Array<{
    pxName: string,
    pxIndex: number,
  }>,
};


// Profile Data
export type ProfileDataResponse = {
  code: string,
  codeDesc: string,
  msg: string,
  data: ProfileDataResponseData,
};

export type ProfileDataResponseData = {
  sizes?: Array<{
    sizeCode: string,
    sizeCmVal: number,
  }>,
  sr?: Array<{
    chartName: string,
    sizeRecmd: string,
  }>,
};


// Point
export type Point = {
  x: number,
  y: number,
};


// Utility
export type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;
