// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //API_URL: 'https://158.132.122.30:31111',
  API_URL: 'https://cshop.emtailor.com/arclothes',
  //API_URL: 'http://158.132.122.207:5108',

  apiBase: 'https://tozi.cafilab.com/open/v2',
  appKey: 'QAgb2OUUNZ',
  appSecret: '3QnsH8LoJx',
  // apiBase: 'https://www.tozmart.com/bnd/tozapi/api/open/v2',
  // appKey: '544204761168617472211210143107',
  // appSecret: '8c41d73ef6a6453193d4330b3a0fc671',
  apiToken:'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJRQWdiMk9VVU5aIn0.0rjzS7XXi4Mnd-g67V3MBAMBkiQy4vcOah6kNv72Axc',
  FASHION_AR_API: 'https://cshop.emtailor.com/arcloths3d',
  AR_IMAGE_API: 'https://158.132.122.207:5106/AssetImages',
  Tutorial_Loading_id: 'my-modal-id',
  Token:'token',
  Username:'Username',
  grant_type:'client',
  userID:'userID',
  qrcode:'qrcode',
  profileID:'profileID'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
